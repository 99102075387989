.tableWrapper {
  overflow: auto;
  font-weight: 500;
}

.header {
  line-height: 1.2;
}

.groupedHeader th:not(:first-child) {
  border-left: 1px solid #fff;
}

.tableRow {
  border-bottom: 1px solid var(--gray-300);
}

.subRow {
  font-weight: 400;
}

.stickyCell {
  position: sticky;
  z-index: 1;
  background-color: inherit;
}

.stickyCellLeft {
  left: 0;
}

.lastStickyCell {
  box-shadow: -1px 0 var(--gray-300) inset;
}

.PW {
  .PW__entries-container {
    color: #84919b;
    font-size: 14px;
  }
}

.PW_Select {
  display: inline-block;
  max-width: 6rem;
}

.PW_Select .select-input__control {
  border-color: rgb(220, 225, 228);
  min-height: 40px !important;
  justify-content: center !important;
}

.PW_Select .select-input__value-container {
  min-width: 62px;
  max-width: 5rem;
  padding: 0 !important;
}

.PW_Select .select-input__single-value {
  font-weight: bold;
}

.PW_Select .select-input__input-container {
  padding: 0 !important;
  margin: 0 !important;
}

.PW_Select .select-input__indicator {
  color: black;
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.sortable {
  cursor: pointer;
}

.sortIndicator {
  margin-left: auto;
  padding-left: 1.5em;
  opacity: 0.25;
}
.sortable:hover .sortIndicator,
.sorting {
  color: var(--blue-vivid-500);
  opacity: 1;
}
